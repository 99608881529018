import React, { useEffect, useState } from "react";
import SideBar from "../components/Sidebar";

import KegHeads from "../layouts/KegHead";

import { Container } from "semantic-ui-react";


import ProductList from './ProductList2'

const Home = () => {

  return (
    <>
      <SideBar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <Container className="py-10" style={{ paddingLeft: 1 + "rem" }}>
        <div class="flex flex-col">
          <ProductList/>
          <div className="my-4">
            <KegHeads />
          </div>
        </div>
        </Container>
      </div>
    </>
  );
};

export default Home;
