import React from "react";
import { Modal, Header, Image, Button } from "semantic-ui-react";

const ModelComponent = ({
  open,
  setOpen,
  img,
  name,
  descriptionENG,
  subdescriptionENG,
  id,
  handleDelete,
}) => {
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header>Product details</Modal.Header>
      <Modal.Content image>
        <Image size="medium" src={img} wrapped></Image>
        <Modal.Description>
          <Header>{name}</Header>
          <p>{descriptionENG}</p>
          <p>{subdescriptionENG}</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            setOpen(false);
          }}
        >
          Ga terug
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ModelComponent;
