import React, {useState, useEffect} from "react";
import {Link} from "react-scroll"
import { db, storage } from "../firebase-config";
import { collection, getDocs, addDoc } from "firebase/firestore";

import {
  ref,
  getDownloadURL,
  listAll,
} from "firebase/storage";

import LazyLoad from "react-lazyload";
import {AnimatePresence} from "framer-motion"

import Colors from '../components/Colors/Colors.js'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from "../components/ModalHome"

import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";
import pngBarrel from "../assets/img/WhatsApp Image 2022-10-24 at 17.01.33 (4).jpeg";
import logoBertin from "../assets/img/label-bertinchamps.png"
import nature from "../assets/img/WhatsApp Image 2022-10-24 at 17.01.33 (2) (1).jpeg";
import titreAvantages from "../assets/img/avantagesTitre.png";
import titreContact from "../assets/img/titreContact.png";
import titreHistoire from "../assets/img/titreHistoire.png";
import {MdOutlineChangeCircle} from "react-icons/md"
import {AiOutlineEuroCircle} from "react-icons/ai"
import {GiRecycle} from "react-icons/gi"
import {GiReceiveMoney} from "react-icons/gi"

//background images for sliding hero section background
import Keg from '../assets/img/WhatsApp1.jpeg'
import Keg2 from '../assets/img/WhatsApp2.jpeg'
import Keg3 from '../assets/img/WhatsApp3.jpeg'

//toggles for sliding between images in hero section
import ChevRight from '../assets/img/icons8-chevron-right-96.png'
import ChevLeft from '../assets/img/icons8-chevron-left-96.png'

//contact form section import 
import ContactForm from './ContactForm'

//swiper for actionaire import
import ActionaireSwiper from '../components/ActionnaireSwiper'
//end swiper for actionaire import


export default function Landing() {
  let iconStyles = { color: "white", fontSize: "3em", margin:"0.2em auto " };

  //START scroll to top function
  const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if(window.scrollY > 400) {
                setBackToTopButton(true)
            } else {
                setBackToTopButton(false)
            }
        })
    }, [])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    p: 4,
  };
  //END scroll to top function

  //START condition for advantages section for polygon image depending on screen width 
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1023);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1023);
  };
  
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  //END condition for advantages section for polygon image depending on screen width 
  
  //START fetch ELEMENTS from db
  //Fetch of kegChoice and kegHead
  const [produits, setProduits] = useState([]);
  const [produit, setProduit] = useState({});
  const [open, setOpen] = React.useState(false);

  const handleModel = (item) => {
      setOpen(true);
      setProduit(item);
  };

  const fetchPost = async () => {
    await getDocs(collection(db, "Images")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setProduits(newData);
      console.log(produits, newData);
    });
  };

  useEffect(() => {
    fetchPost();
  }, []);

  //Fetch for trust partners 
  const [imageUrls, setImageUrls] = useState([]);
  const imagesListRef = ref(storage, "trustPartners/");

  useEffect(() => {
    listAll(imagesListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageUrls((prev) => [...prev, url]);
        });
      });
    });
  }, []);
  //END fetch ELEMENTS from db

  //START Hero section function for background slider 
  const kegImages = [ Keg , Keg2, Keg3 ];

  const [countSlider, setCountSlider] = useState(0);
  const [kegSlider, setKegSlider] = useState();
  
  //start background img on index 1
  useEffect(() => {
    setKegSlider(kegImages[1])
  }, []);

  //move images to right
  const handleChangeSliderR = () => {
    setCountSlider(+countSlider + 1);
    if (countSlider == 2) {
      setCountSlider(0)
    }
    setKegSlider(kegImages[countSlider]);
  }

  //move image to left
  const handleChangeSliderL = () => {
    setCountSlider(+countSlider - 1);
    if (countSlider == 0) {
      setCountSlider(2)
    }
    setKegSlider(kegImages[countSlider]);
  }
  //END Hero section function for background slider 


  return (
    <>
      <Navbar transparent />

      <main name="accueuil">

        <div
          className="relative flex items-center content-center justify-center pt-16"
          style={{
            maxHeight: "100vh",
          }} 
        >
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover brightness-50 ease-in duration-700"
            style={{
              backgroundColor: Colors.background,
              backgroundImage: `url(${kegSlider})`
            }}
          ></div>
          <div className="container relative mx-auto" > 
            {backToTopButton && (
              <button
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
                style={{
                  position: "fixed",
                  padding: "0.5rem 1rem",
                  fontSize: "15px",
                  fontWeight: "bold",
                  bottom: "40px",
                  right: "10px",
                  color: Colors.text,
                  borderColor: Colors.text,
                  border: "2px solid",
                  backgroundColor: Colors.background,
                  textAlign: "center",
                  zIndex: "99",
                  borderRadius: "5px",
                }}
              >
                Revenir en haut
              </button>
            )}
            <div className="flex flex-wrap items-center w-full lg:p-24 md:p-40 sm:py-32 xs:py-24">
              <div className="p-4 m-auto ml-auto text-center xs:p-0 lg:w-8/12 md:10/12 sm:12/12">
                <div>
                  <div className="flex items-center justify-center w-full h-screen">
                    <img onClick={handleChangeSliderL} src={ChevLeft} width="60px" className="cursor-pointer"></img>
                    <div>
                    <h1
                      className="m-auto lg:text-4xl md:text-3xl sm:text-2xl xs:text-xl lg:w-3/4 md:w:1/2 sm:w-full"
                      style={{
                        color: Colors.text,
                      }}
                    >
                      La solution de location de fûts pour augmenter vos ventes
                      tout en limitant vos dépenses d'investissements
                    </h1>
                    <p className="absolute w-fit left-0 right-0 m-auto font-black px-5 mt-10 text-black uppercase bg-white lg:text-lg md:text-sm xs:text-xs rounded-md">" Tarif mensuel à partir de 1.5 eur htva par fût "</p> 
                    </div>
                    <img onClick={handleChangeSliderR} src={ChevRight} width="60px" className="cursor-pointer"></img>
                  </div>
                  {/* <button class="bg-white text-grey-darkest font-bold py-4 px-6 mt-12 rounded inline-flex items-center">
                    <svg
                      class="w-4 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                    </svg>
                    <span>La location futée</span>
                  </button> */}
                  </div>
                </div>
                {/* <div
                  name="services"
                  className="p-4 m-auto ml-auto text-center xs:p-0 lg:w-6/12 md:10/12 sm:12/12"
                >
                  <img
                    src={Keg}
                    className="m-auto"
                    width="80%"
                    height="30%"
                    alt="logo keg"
                  ></img>
                </div> */}
              </div>
          </div>
        </div>
        <section className="pb-20 -mt-24 bg-white" name="services">
            <div className="container px-4 mx-auto">
              <div className="flex flex-wrap h-full items-center" >

                <div className="w-full px-4 pt-6 text-center xs:w-full md:w-6/12 lg:w-3/12">
                  <div className="relative flex h-56 flex-col w-full min-w-0 mb-8 break-words bg-white rounded-lg shadow-lg">
                    <div className="flex-auto px-4 py-5">
                      <div
                        className="inline-flex items-center justify-center w-12 h-12 p-3 mb-5 text-center rounded-full shadow-lg"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <i className="fas fa-bolt"></i>
                      </div>
                      <h6 className="text-xl font-semibold">Rapidité</h6>
                      <p className="mt-2 mb-4 text-gray-700">
                        Nous vous livrons vos fûts prêts à l'emploi en 3 à 5
                        semaines
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full px-4 pt-6 text-center xs:w-full md:w-6/12 lg:w-3/12">
                  <div className="relative flex h-56 flex-col w-full min-w-0 mb-8 break-words bg-white rounded-lg shadow-lg">
                    <div className="flex-auto px-4 py-5">
                      <div
                        className="inline-flex items-center justify-center w-12 h-12 p-3 mb-5 text-center rounded-full shadow-lg"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <i className="fas fa-expand-arrows-alt"></i> 
                      </div>
                      <h6 className="text-xl font-semibold">Flexibilité</h6>
                      <p className="mt-2 mb-4 text-gray-700">
                        Vous choisissez le nombre de fûts dont vous avez besoin
                        et la durée de location
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full px-4 pt-6 text-center xs:w-full md:w-6/12 lg:w-3/12">
                  <div className="relative flex h-56 flex-col w-full min-w-0 mb-8 break-words bg-white rounded-lg shadow-lg">
                    <div className="flex-auto px-4 py-5">
                      <div
                        className="inline-flex items-center justify-center w-12 h-12 p-3 mb-5 text-center rounded-full shadow-lg"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <i className="fas fa-star"></i>
                      </div>
                      <h6 className="text-xl font-semibold">Nettoyage</h6>
                      <p className="mt-2 mb-4 text-gray-700">
                        Vous n'avez pas l'équipement pour mettre en fût ou les
                        nettoyer, nous avons des solutions pour vous
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full px-4 pt-6 text-center xs:w-full md:w-6/12 lg:w-3/12">
                  <div className="relative flex h-56 flex-col w-full min-w-0 mb-8 break-words bg-white rounded-lg shadow-lg">
                    <div className="flex-auto px-4 py-5">
                      <div
                        className="inline-flex items-center justify-center w-12 h-12 p-3 mb-5 text-center rounded-full shadow-lg"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <i className="fas fa-handshake"></i>
                      </div>
                      <h6 className="text-xl font-semibold">Service</h6>
                      <p className="mt-2 mb-4 text-gray-700">
                        Nous sommes à votre disposition pour les entretiens et
                        remplacements
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                name="avantages"
                className="flex flex-wrap items-center w-full py-10 justify-evenly"
                style={{
                  color: Colors.background,
                }}
              >
                <img
                  width="300px"
                  className="m-auto "
                  src={titreAvantages}
                ></img>
                <h1 className="py-6 text-xl text-center uppercase lg:w-3/4 md:w-full sm:w-full">
                  “Louez vos fûts en fonction de vos besoins, ne les achetez
                  plus !”
                </h1>
              </div>
              <LazyLoad height={200} offset={100}>
                <div className="items-center text-center lg:flex">
                  <div className="grid content-center grid-cols-1 gap-12 md:grid-cols-2 place-items-center">

                    <div className="h-56">
                      <a
                        href="#"
                        className="block p-3 ml-auto h-full mr-auto border border-gray-200 rounded-lg shadow-lg pointer-events-none hover:bg-red-900"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <MdOutlineChangeCircle style={iconStyles} />
                        <h5 className="mb-2 text-2xl font-bold tracking-tight">
                        Maitrise de rotation
                        </h5>
                        <p
                          className="font-normal dark:text-gray-400"
                          style={{
                            color: Colors.text,
                          }}
                        >
                          Notre objectif est de vous aider à garder une
                          meilleure maitrise globale de la rotation de vos fûts
                        </p>
                      </a>
                    </div>

                    <div className="h-56">
                      <a
                        href="#"
                        className="block p-3 ml-auto h-full mr-auto border border-gray-200 rounded-lg shadow-lg pointer-events-none hover:bg-red-900"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <AiOutlineEuroCircle style={iconStyles} />
                        <h5 className="mb-2 text-2xl font-bold tracking-tight">
                          Locafût à l'affût
                        </h5>
                        <p
                          className="font-normal dark:text-gray-400"
                          style={{
                            color: Colors.text,
                          }}
                        >
                          Nous vous aidons à optimiser vos frais en fonction de
                          vos commandes et à limiter vos coûts de production en
                          favorisant l’utilisation du fût
                        </p>
                      </a>
                    </div>

                    <div className="h-56">
                      <a
                        href="#"
                        className="block p-3 ml-auto h-full mr-auto border border-gray-200 rounded-lg shadow-lg pointer-events-none hover:bg-red-900"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <GiReceiveMoney style={iconStyles} />
                        <h5 className="mb-2 text-2xl font-bold tracking-tight">
                          Tarifs compétitifs
                        </h5>
                        <p
                          className="font-normal dark:text-gray-400"
                          style={{
                            color: Colors.text,
                          }}
                        >
                          Tarifs de location les plus bas du marché et
                          dégressifs en fonction du nombre de fûts et de la
                          durée de location
                        </p>
                      </a>
                    </div>

                    <div className="h-56">
                      <a
                        href="#"
                        className="block p-3 h-full ml-auto mr-auto border border-gray-200 rounded-lg shadow-lg pointer-events-none hover:bg-red-900"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <GiRecycle style={iconStyles} />
                        <h5 className="mb-2 text-2xl font-bold tracking-tight">
                        Ecologique
                        </h5>
                        <p
                          className="font-normal dark:text-gray-400"
                          style={{
                            color: Colors.text,
                          }}
                        >
                          Nos fûts sont réutilisables plusieurs années et
                          recyclables en fin de vie pour limiter vos coûts de
                          transport et améliorer votre empreinte carbone
                        </p>
                      </a>
                    </div>

                  </div>

                  {isDesktop ? (
                    <div className="w-4/5 px-4 py-10 m-auto sm:w-full md:w-5/5 xs:invisible xs:w-0 md:visible">
                      <div className="flex flex-col items-center w-full mb-6 break-words bg-transparent rounded-md md:relative">
                        <img
                          alt="Kegs dans la nature"
                          height="100%"
                          src={nature}
                          style={{
                            clipPath:
                              "polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 25% 50%, 0% 0%)",
                          }}
                          className="align-middle rounded-t-lg w-5/5"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="w-4/5 px-4 py-10 m-auto sm:w-full md:w-5/5 xs:invisible xs:w-0 md:visible">
                      <div className="flex flex-col items-center w-full mb-6 break-words bg-transparent rounded-md md:relative">
                        <img
                          alt="Kegs dans la nature"
                          height="100%"
                          src={nature}
                          style={{
                            clipPath:
                              "polygon(50% 45%, 50% 45%, 100% 0, 100% 100%, 70% 100%, 30% 100%, 0 100%, 0 0)",
                          }}
                          className="w-4/5 align-middle rounded-t-lg"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </LazyLoad>
            </div>

            <div className="flex flex-col flex-wrap justify-center">
              <blockquote className="relative mb-4">
                {/* <h4 className="text-xl font-bold text-white">
                Pourquoi des fûts en acier?
              </h4> */}
                <p
                  className="w-full p-8 m-auto mt-2 font-semibold text-center uppercase text-md"
                  style={{
                    color: Colors.background,
                  }}
                >
                  "L'USAGE DU FÛT EST PLUS ÉCONOMIQUE, PLUS ÉCOLOGIQUE ET PLUS RENTABLE"
                </p>
              </blockquote>
              <div className="text-center">
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  <button
                    className={
                      "bg-white text-gray-800 active:bg-gray-100 text-center  m-auto" +
                      " text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 cursor-pointer"
                    }
                    type="button"
                    style={{
                      transition: "all .15s ease",
                      color: Colors.text,
                      backgroundColor: Colors.background,
                    }}
                  >
                    <i className="fas fa-arrow-alt-circle-down"></i> Demandez votre
                    devis
                  </button>
                </Link>
              </div>
            </div>
        </section>

        <LazyLoad height={200} offset={100} name="histoire">
            <section className="relative bg-black" name="histoire" >
              <div
                className="container px-4 mx-auto rounded-md"
                style={{
                  backgroundColor: Colors.background,
                  color: Colors.text,
                }}
              >
                <div className="items-center lg:flex lg:flex-wrap md:inline-block">
                  <div className="w-full p-5 ml-auto mr-auto md:w-4/12 xs:invisible xs:w-0 md:visible ">
                    <img
                      alt="..."
                      className="max-w-full rounded-lg shadow-lg"
                      src={pngBarrel}
                    />
                  </div>
                  <div className="w-full p-4 ml-auto mr-auto md:w-10/12 lg:w-5/12">
                    <img
                      src={titreHistoire}
                      width="300px"
                      className="m-auto"
                    ></img>
                    <div>
                      <p className="w-full m-auto mt-4 text-lg leading-relaxed text-justify md:h-auto xs:h-80 xs:overflow-auto">
                        Locafut est une startup qui a été créé par des amateurs
                        de bière dans le but d'offrir aux brasseries et autres
                        acteurs HORECA, un service flexible de location et
                        entretien de fûts en acier de qualité, et accessoires en
                        garantissant des tarifs les plus compétitifs du marché.
                        Nous avons pour ambition d’aider les brasseurs à gérer
                        leur pic de production, à envisager des alternatives
                        pour limiter la hausse de leur coût de production,
                        d’encourager une politique éco-responsable en favorisant
                        l’usage illimité du fûts. Nous restons flexible et
                        mettons un point d’honneur à nous adapter à vos besoins
                        pour accompagner votre évolution.
                      </p>
                      <ul className="flex flex-wrap justify-between w-full px-4 mt-6 list-none">
                        <li className="py-2">
                          <div className="flex items-center">
                            <div>
                              <span
                                className="inline-block px-2 py-1 mr-3 text-xs font-semibold uppercase rounded-full"
                                style={{
                                  backgroundColor: Colors.historyTag,
                                  color: Colors.text,
                                }}
                              >
                                <i className="fa fa-thumbs-up"></i>
                              </span>
                            </div>
                            <div>
                              <h4>Usage illimité du fût</h4>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="flex items-center">
                            <div>
                              <span
                                className="inline-block px-2 py-1 mr-3 text-xs font-semibold uppercase rounded-full"
                                style={{
                                  backgroundColor: Colors.historyTag,
                                  color: Colors.text,
                                }}
                              >
                                <i className="fa fa-trophy"></i>
                              </span>
                            </div>
                            <div>
                              <h4>Prix compétitifs</h4>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="flex items-center">
                            <div>
                              <span
                                className="inline-block px-2 py-1 mr-3 text-xs font-semibold uppercase rounded-full"
                                style={{
                                  backgroundColor: Colors.historyTag,
                                  color: Colors.text,
                                }}
                              >
                                <i className="far fa-paper-plane"></i>
                              </span>
                            </div>
                            <div>
                              <h4>Rapidité</h4>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <section className="py-12">
                  <div className="container px-4 mx-auto">
                    <div className="flex flex-wrap justify-center mb-5 text-center">
                      <div className="w-full px-4 lg:w-6/12">
                        <h2 className="text-4xl font-semibold">
                          Ils nous font déjà confiance
                        </h2>
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center w-full">
                    {imageUrls.map((produits) => (
                      <img className="m-5" width="150px" src={produits}></img>
                    ))}
                    </div>
                  </div>
                </section>
              </div>
            </section>
        </LazyLoad>

        <section className="py-12">
            <div className="container px-4 mx-auto">
              <div className="flex flex-wrap justify-center mb-24 text-center">
                <div className="w-full px-4 lg:w-6/12">
                  <h2 className="text-4xl font-semibold">Nos produits</h2>
                  <p className="m-4 text-lg leading-relaxed text-gray-600">
                    Découvrez nos fûts en acier inoxydable
                  </p>
                </div>
              </div>
              <LazyLoad height={200} offset={100}>
                <div className="flex flex-wrap justify-center">
                  {produits.map((produits) => (
                    <div
                      key={produits.id}
                      className="w-full px-4 mb-12 md:w-6/12 lg:w-3/12 lg:mb-0"
                    >
                      <div className="px-6">
                        <img
                          alt="Image de fût"
                          src={produits.img}
                          className="max-w-full mx-auto rounded-full shadow-lg"
                          style={{ maxWidth: "200px", minHeight: "200px" }}
                        />
                        <div className="pt-6 text-center">
                          <h5 className="text-xl font-bold">{produits.name}</h5>
                          {/* <p className="mt-1 text-sm font-semibold text-gray-500 uppercase">
                            {produits.description}
                          </p> */}
                          <Button onClick={() => handleModel(produits)}>
                            Voir Plus
                          </Button>

                          {open && (
                            <Modal setOpen={setOpen} open={open} {...produit}>
                              <Box className="border-none" sx={style}>
                                <div
                                  id="partnerCard"
                                  className="flex flex-col h-auto max-w-sm p-2 overflow-hidden border-none rounded-md text-gray-50"
                                  style={{
                                    backgroundColor: Colors.background,
                                    color: Colors.text,
                                  }}
                                >
                                  <div>
                                    <h3 className="pt-2 pb-4 pl-8 text-xl text-left">
                                      {produits.name}
                                    </h3>
                                  </div>
                                  <div className="flex items-center justify-center h-auto bg-white">
                                    <a
                                      className="flex items-center justify-center"
                                      href="#"
                                      target="_blank"
                                      rel="noreferrer noopener"
                                    >
                                      <img
                                        src={produits.img}
                                        alt="Euro Keg"
                                        className="object-cover w-1/2"
                                      />
                                    </a>
                                  </div>
                                  <div className="grid">
                                    <div className="w-full col-span-3 p-4 pr-0 text-lg">
                                      <h4 className="font-bold underline">
                                        Spécificité
                                      </h4>
                                      <ul>
                                        <li className="w-full my-3 text-left">
                                          {produits.subdescription}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </Box>
                            </Modal>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </LazyLoad>
            </div>
        </section>

        <section
            className="relative block pb-20"
            name="contact"
            style={{
              backgroundColor: Colors.background,
            }}
          >
            <div className="container px-4 mx-auto lg:pt-12 lg:pb-52">
              <div className="flex flex-wrap justify-center text-center">
                <div className="w-full px-4 lg:w-6/12">
                  <img
                    width="300px"
                    className="m-auto lg:py-0 md:py-6 xs:py-10 "
                    src={titreContact}
                  ></img>
                </div>
              </div>
            </div>
        </section>

        <ContactForm/>

        <LazyLoad height={200} offset={100} name="actionnaires">
            <section className="relative bg-black" name="actionnaires" >
              <div
                className="container m-auto align-center justify-around text-center p-5 rounded-md"
                style={{
                  backgroundColor: Colors.background,
                  color: Colors.text,
                }}
              >
                <section className="py-12">
                  <div className="container">
                    <div className="flex flex-wrap justify-center mb-5 text-center">
                      <div className="">
                        <h2 className="text-4xl font-semibold">
                          Qui sommes-nous
                        </h2>
                        <p className="w-full m-auto mt-4 text-lg leading-relaxed text-justify md:h-auto">Découvrez l'équipe derrière ce projet</p>

                      </div>
                    </div>
                    <div className="justify-center m-auto w-full">
                      <ActionaireSwiper/>
                    </div>
                  </div>
                </section>
              </div>
            </section>
        </LazyLoad>
          
      </main>
      
      <Footer />
    </>
  );
}