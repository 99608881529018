/*eslint-disable*/
import React, {useContext} from "react";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import {auth} from "../firebase-config"
import {FaBars} from "react-icons/fa"
import Logo from "../assets/img/logoFutVert.png"
import {AuthContext} from "../context/AuthContext"


export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const currentUser = useContext(AuthContext);
  const navigate = useNavigate();

   const clickLogin = () => {
     if (currentUser) {
       signOut(auth);
       localStorage.removeItem("user")
       navigate("/login");
     }
   };
  
  return (
    <>
      <nav className="relative z-10 flex flex-wrap items-center justify-between px-6 py-4 bg-green-600 shadow-xl md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden md:w-64">
        <div className="flex flex-wrap items-center justify-between w-full px-0 mx-auto md:flex-col md:items-stretch md:min-h-full md:flex-nowrap">
          {/* Toggler */}
          <button
            className="px-3 py-1 text-xl leading-none text-black bg-transparent border border-transparent border-solid rounded opacity-50 cursor-pointer md:hidden"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <FaBars />
          </button>
          {/* Brand */}
          <Link
            className="inline-block p-4 px-0 mr-0 text-sm font-bold text-left uppercase md:block md:pb-2 text-blueGray-600 whitespace-nowrap"
            to="/"
          >
            <img src={Logo} />
          </Link>
          {/* User */}
          <ul className="flex flex-wrap items-center list-none md:hidden">
            <li className="relative inline-block"></li>
            <li className="relative inline-block"></li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex bg-green-600 md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="block pb-4 mb-4 border-b border-solid md:min-w-full md:hidden ">
              <div className="flex flex-wrap ">
                <div className="w-6/12">
                  <Link
                    className="inline-block p-4 px-0 mr-0 text-sm font-bold text-left uppercase md:block md:pb-2 text-blueGray-600 whitespace-nowrap"
                    to="/"
                  >
                    LOCAFûT
                  </Link>
                </div>
                <div className="flex justify-end w-6/12 ">
                  <button
                    type="button"
                    className="px-3 py-1 text-xl leading-none text-black bg-transparent border border-transparent border-solid rounded opacity-50 cursor-pointer md:hidden"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <FaBars />
                  </button>
                </div>
              </div>
            </div>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            {/* Navigation */}

            <ul className="flex flex-col list-none md:flex-col md:min-w-full ">
              <li className="items-center ">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") 
                      ? "text-orange-200 hover:text-orange-300"
                      : "text-white-700 hover:text-white-500")
                  }
                  to="/admin/dashboard"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/dashboard") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Tableau de bord
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/products") 
                      ? "text-orange-200 hover:text-orange-300"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/products"
                >
                  <i
                    className={
                      "fas fa-shopping-cart mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/products") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Produits
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/actionnaires") 
                      ? "text-orange-200 hover:text-orange-300"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/actionnaires"
                >
                  <i
                    className={
                      "fas fa-user mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/actionnaires") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Actionnaires
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/contactForm") 
                      ? "text-orange-200 hover:text-orange-300"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/contactForm"
                >
                  <i
                    className={
                      "fas fa-book mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/contactForm") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Contact Forms
                </Link>
              </li>

              {/* <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/fr") 
                      ? "text-orange-200 hover:text-orange-300"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/fr"
                >
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/fr") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Site français
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/nl") 
                      ? "text-orange-200 hover:text-orange-300"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/nl"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/nl") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Site néerlandais
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/eng") 
                      ? "text-orange-200 hover:text-orange-300"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/eng"
                >
                  <i
                    className={
                      "fas fa-map-marked mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/eng") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Site anglais
                </Link>
              </li> */}

              <li className="items-center">
                <button onClick={clickLogin}
                  className={
                    "text-xs text-orange-200 hover:text-orange-300 uppercase py-3 font-bold block"}
                  to="/admin/eng"
                  
                >
                <i
                    className="fas fa-arrow-left mr-2 text-sm opacity-75"
                  ></i>
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
