import React, {useState, useEffect} from "react";
import Swal from 'sweetalert2'
import {Link} from "react-scroll"
import LazyLoad from "react-lazyload";

import { db, storage } from "../../firebase-config";
import { collection, getDocs, addDoc } from "firebase/firestore";

import {
  ref,
  getDownloadURL,
  listAll,
} from "firebase/storage";

import Colors from '../components/Colors/Colors.js'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from "../../components/ModalHomeNL";

import Keg from "../../assets/img/WhatsApp1.jpeg"
import Keg2 from '../../assets/img/WhatsApp2.jpeg'
import Keg3 from '../../assets/img/WhatsApp3.jpeg'


import Navbar from "../../DUTCH/components/Navbar.js";
import Footer from "../../DUTCH/components/Footer";
import pngBarrel from "../../assets/img/WhatsApp Image 2022-10-24 at 17.01.33 (4).jpeg";
import logoBertin from "../../assets/img/label-bertinchamps.png"
import nature from "../../assets/img/WhatsApp Image 2022-10-24 at 17.01.33 (2) (1).jpeg";
import titreAvantages from "../../assets/img/voordelen.png";
import titreContact from "../../assets/img/titreContact.png";
import titreHistoire from "../../assets/img/geschiendenis.png";
import {MdOutlineChangeCircle} from "react-icons/md"
import {AiOutlineEuroCircle} from "react-icons/ai"
import {GiRecycle} from "react-icons/gi"
import {GiReceiveMoney} from "react-icons/gi"



import ChevRight from '../../assets/img/icons8-chevron-right-96.png'
import ChevLeft from '../../assets/img/icons8-chevron-left-96.png'


//contact form section import 
import ContactForm from './ContactForm'

//swiper for actionaire import
import ActionaireSwiper from '../components/ActionnaireSwiper'
//end swiper for actionaire import


export default function Landing() {
  let iconStyles = { color: "white", fontSize: "3em", margin:"0.2em auto " };

  const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if(window.scrollY > 400) {
                setBackToTopButton(true)
            } else {
                setBackToTopButton(false)
            }
        })
    }, [])

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  p: 4,
};

  const [produits, setProduits] = useState([]);
  const [produit, setProduit] = useState({});
  const [open, setOpen] = React.useState(false);

  const handleModel = (item) => {
    setOpen(true);
    setProduit(item);
  };


  const [isDesktop, setDesktop] = useState(window.innerWidth > 1023);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1023);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const fetchPost = async () => {
    await getDocs(collection(db, "Images")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setProduits(newData);
      console.log(produits, newData);
    });
  };

  useEffect(() => {
    fetchPost();
  }, []);

  const kegImages = [ Keg , Keg2, Keg3 ];

  const [countSlider, setCountSlider] = useState(0);
  const [kegSlider, setKegSlider] = useState();
  

  useEffect(() => {
    setKegSlider(kegImages[2])
  }, []);

  const handleChangeSliderR = () => {
    setCountSlider(+countSlider + 1);
    if (countSlider == 2) {
      setCountSlider(0)
    }
    setKegSlider(kegImages[countSlider]);
  }

  const handleChangeSliderL = () => {
    setCountSlider(+countSlider - 1);
    if (countSlider == 0) {
      setCountSlider(2)
    }
    setKegSlider(kegImages[countSlider]);
  }

    //Fetch for trust partners 
    const [imageUrls, setImageUrls] = useState([]);
    const imagesListRef = ref(storage, "trustPartners/");
  
    useEffect(() => {
      listAll(imagesListRef).then((response) => {
        response.items.forEach((item) => {
          getDownloadURL(item).then((url) => {
            setImageUrls((prev) => [...prev, url]);
          });
        });
      });
    }, []);
    //END fetch ELEMENTS from db


  return (
    <>
      <Navbar transparent />

      <main name="accueuil">

        <div
          className="relative flex items-center content-center justify-center pt-16"
          style={{
            maxHeight: "100vh",
          }}
        >
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover brightness-50 ease-in duration-700"
            style={{
              backgroundColor: Colors.background,
              backgroundImage: `url(${kegSlider})`
            }}
          ></div>
          <div className="container relative mx-auto">
            {backToTopButton && (
              <button
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
                style={{
                  position: "fixed",
                  padding: "0.5rem 1rem",
                  fontSize: "15px",
                  fontWeight: "bold",
                  bottom: "40px",
                  right: "10px",
                  color: Colors.text,
                  borderColor: Colors.text,
                  border: "2px solid",
                  backgroundColor: Colors.background,
                  textAlign: "center",
                  zIndex: "99",
                  borderRadius: "5px",
                }}
              >
                Terug naar boven
              </button>
            )}
            <div className="flex flex-wrap items-center w-full lg:p-24 md:p-40 sm:py-32 xs:py-24">
              <div className="p-4 m-auto ml-auto text-center xs:p-0 lg:w-8/12 md:10/12 sm:12/12">
                <div>
                  {/* <div className="flex items-center justify-center w-full h-screen">
                    <img onClick={handleChangeSliderL} src={ChevLeft} width="60px" className="cursor-pointer"></img>
                    <h1
                      className="m-auto lg:text-4xl md:text-3xl sm:text-2xl xs:text-xl lg:w-3/4 md:w:1/2 sm:w-full"
                      style={{
                        color: Colors.text,
                      }}
                    >
                      De oplossing voor het verhuren van vaten om uw omzet te verhogen en tegelijkertijd uw uitgaven te beperken
                    </h1>
                    <img onClick={handleChangeSliderR} src={ChevRight} width="60px" className="cursor-pointer"></img>
                  </div> */}
                  <div className="flex items-center justify-center w-full h-screen">
                    <img onClick={handleChangeSliderL} src={ChevLeft} width="60px" className="cursor-pointer"></img>
                    <div>
                    <h1
                      className="m-auto lg:text-4xl md:text-3xl sm:text-2xl xs:text-xl lg:w-3/4 md:w:1/2 sm:w-full"
                      style={{
                        color: Colors.text,
                      }}
                    >
                      De trommelverhuuroplossing om uw verkoop te verhogen
                      terwijl u uw kapitaaluitgaven beperkt
                    </h1>
                    <p className="absolute w-fit left-0 right-0 m-auto font-black px-5 mt-10 text-black uppercase bg-white lg:text-lg md:text-sm xs:text-xs rounded-md">" Maandelijks tarief vanaf 1,5 eur excl. BTW per vat "</p> 
                    </div>
                    <img onClick={handleChangeSliderR} src={ChevRight} width="60px" className="cursor-pointer"></img>
                  </div>
                  {/* <button class="bg-white text-grey-darkest font-bold py-4 px-6 mt-12 rounded inline-flex items-center">
                    <svg
                      class="w-4 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                    </svg>
                    <span>La location futée</span>
                  </button> */}
                  </div>
                </div>
                {/* <div
                  name="services"
                  className="p-4 m-auto ml-auto text-center xs:p-0 lg:w-6/12 md:10/12 sm:12/12"
                >
                  <img
                    src={Keg}
                    className="m-auto"
                    width="80%"
                    height="30%"
                    alt="logo keg"
                  ></img>
                </div> */}
              </div>
            </div>
          </div>

          <section name="diensten" className="pb-20 -mt-24 bg-white">
            <div className="container px-4 mx-auto">
              <div className="flex flex-wrap items-center ">
                <div className="w-full px-4 pt-6 text-center xs:w-full md:w-6/12 lg:w-3/12">
                  <div className="relative flex flex-col w-full min-w-0 mb-8 break-words bg-white rounded-lg shadow-lg">
                    <div className="flex-auto px-4 py-5">
                      <div
                        className="inline-flex items-center justify-center w-12 h-12 p-3 mb-5 text-center rounded-full shadow-lg"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <i className="fas fa-bolt"></i>
                      </div>
                      <h6 className="text-xl font-semibold">Snelheid</h6>
                      <p className="mt-2 mb-4 text-gray-700">
                      Wij leveren uw gebruiksklare vaten binnen 3 tot 5
                        weken
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full px-4 pt-6 text-center xs:w-full md:w-6/12 lg:w-3/12">
                  <div className="relative flex flex-col w-full min-w-0 mb-8 break-words bg-white rounded-lg shadow-lg">
                    <div className="flex-auto px-4 py-5">
                      <div
                        className="inline-flex items-center justify-center w-12 h-12 p-3 mb-5 text-center rounded-full shadow-lg"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <i className="fas fa-expand-arrows-alt"></i> 
                      </div>
                      <h6 className="text-xl font-semibold">Flexibiliteit</h6>
                      <p className="mt-2 mb-4 text-gray-700">
                      U kiest het aantal vaten dat u nodig hebt
                        en de huurperiode
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full px-4 pt-6 text-center xs:w-full md:w-6/12 lg:w-3/12">
                  <div className="relative flex flex-col w-full min-w-0 mb-8 break-words bg-white rounded-lg shadow-lg">
                    <div className="flex-auto px-4 py-5">
                      <div
                        className="inline-flex items-center justify-center w-12 h-12 p-3 mb-5 text-center rounded-full shadow-lg"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <i className="fas fa-star"></i>
                      </div>
                      <h6 className="text-xl font-semibold">Schoonmaken</h6>
                      <p className="mt-2 mb-4 text-gray-700">
                      U heeft niet de apparatuur om ze te vullen of te reinigen
                        wij hebben oplossingen voor u
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full px-4 pt-6 text-center xs:w-full md:w-6/12 lg:w-3/12">
                  <div className="relative flex flex-col w-full min-w-0 mb-8 break-words bg-white rounded-lg shadow-lg">
                    <div className="flex-auto px-4 py-5">
                      <div
                        className="inline-flex items-center justify-center w-12 h-12 p-3 mb-5 text-center rounded-full shadow-lg"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <i className="fas fa-handshake"></i>
                      </div>
                      <h6 className="text-xl font-semibold">Dienst</h6>
                      <p className="mt-2 mb-4 text-gray-700">
                      Wij staan tot uw beschikking voor onderhoud en
                        vervangingen
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                name="voordelen"
                className="flex flex-wrap items-center w-full py-10 justify-evenly"
                style={{
                  color: Colors.background,
                }}
              >
                <img
                  width="300px"
                  className="m-auto "
                  src={titreAvantages}
                ></img>
                <h1 className="py-6 text-xl text-center uppercase lg:w-3/4 md:w-full sm:w-full">
                  “Huur uw vaten volgens uw behoeften, koop ze niet
                  niet meer !”
                </h1>
              </div>
              <LazyLoad height={200} offset={100}>
                <div className="items-center text-center lg:flex">
                  <div className="grid content-center grid-cols-1 gap-12 md:grid-cols-2 place-items-center">
                    <div className="">
                      <a
                        href="#"
                        className="block max-w-sm min-h-full p-3 border border-gray-200 rounded-lg shadow-lg pointer-events-none hover:bg-red-900"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <AiOutlineEuroCircle style={iconStyles} />
                        <h5 className="mb-2 text-2xl font-bold tracking-tight dark:text-white">
                        Rotatie maïs
                        </h5>
                        <p
                          className="font-normal dark:text-gray-400"
                          style={{
                            color: Colors.text,
                          }}
                        >
                          Ons doel is u te helpen een betere
                          betere algemene controle over uw trommelrotatie
                        </p>
                      </a>
                    </div>
                    <div className="">
                      <a
                        href="#"
                        className="block p-3 ml-auto mr-auto border border-gray-200 rounded-lg shadow-lg pointer-events-none min-w-lg min-h-max hover:bg-red-900"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <MdOutlineChangeCircle style={iconStyles} />
                        <h5 className="mb-2 text-2xl font-bold tracking-tight">
                        Locafût op de uitkijk
                        </h5>
                        <p
                          className="font-normal dark:text-gray-400"
                          style={{
                            color: Colors.text,
                          }}
                        >
                         Wij helpen u uw kosten te optimaliseren in functie van uw
                          uw bestellingen en uw productiekosten te beperken door
                          het gebruik van vaten te bevorderen
                        </p>
                      </a>
                    </div>
                    <div className="">
                      <a
                        href="#"
                        className="block max-w-sm min-h-full p-3 border border-gray-200 rounded-lg shadow-lg pointer-events-none hover:bg-red-900"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <GiReceiveMoney style={iconStyles} />
                        <h5 className="mb-2 text-2xl font-bold tracking-tight dark:text-white">
                        Concurrerende tarieven
                        </h5>
                        <p
                          className="font-normal dark:text-gray-400"
                          style={{
                            color: Colors.text,
                          }}
                        >
                          De laagste huurprijzen op de markt en
                          afhankelijk van het aantal vaten en de duur van
                          huurperiode
                        </p>
                      </a>
                    </div>
                    <div className="">
                      <a
                        href="#"
                        className="block max-w-sm min-h-full p-3 border border-gray-200 rounded-lg shadow-lg pointer-events-none hover:bg-red-900"
                        style={{
                          backgroundColor: Colors.background,
                          color: Colors.text,
                        }}
                      >
                        <GiRecycle style={iconStyles} />
                        <h5 className="mb-2 text-2xl font-bold tracking-tight dark:text-white">
                        Ecologisch
                        </h5>
                        <p
                          className="font-normal dark:text-gray-400"
                          style={{
                            color: Colors.text,
                          }}
                        >
                          Onze vaten zijn meerdere jaren herbruikbaar en
                          recycleerbaar aan het einde van hun levensduur om uw
                          en uw ecologische voetafdruk te verbeteren.
                        </p>
                      </a>
                    </div>
                  </div>

                  {isDesktop ? (
                    <div className="w-4/5 px-4 py-10 m-auto sm:w-full md:w-5/5 xs:invisible xs:w-0 md:visible">
                      <div className="flex flex-col items-center w-full mb-6 break-words bg-transparent rounded-md md:relative">
                        <img
                          alt="Kegs dans la nature"
                          height="100%"
                          src={nature}
                          style={{
                            clipPath:
                              "polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 25% 50%, 0% 0%)",
                          }}
                          className="align-middle rounded-t-lg w-5/5"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="w-4/5 px-4 py-10 m-auto sm:w-full md:w-5/5 xs:invisible xs:w-0 md:visible">
                      <div className="flex flex-col items-center w-full mb-6 break-words bg-transparent rounded-md md:relative">
                        <img
                          alt="Kegs dans la nature"
                          height="100%"
                          src={nature}
                          style={{
                            clipPath:
                              "polygon(50% 45%, 50% 45%, 100% 0, 100% 100%, 70% 100%, 30% 100%, 0 100%, 0 0)",
                          }}
                          className="w-4/5 align-middle rounded-t-lg"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </LazyLoad>
            </div>

            <div className="flex flex-col flex-wrap justify-center">
              <blockquote className="relative mb-4">
                {/* <h4 className="text-xl font-bold text-white">
                Pourquoi des fûts en acier?
              </h4> */}
                <p
                  className="w-full p-8 m-auto mt-2 font-semibold text-center uppercase text-md"
                  style={{
                    color: Colors.background,
                  }}
                >
                  "HET GEBRUIK VAN VATEN IS ECONOMISCHER, MILIEUVRIENDELIJKER EN KOSTENEFFECTIEVER"
                </p>
              </blockquote>
              <div className="text-center">
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  <button
                    className={
                      "bg-white text-gray-800 active:bg-gray-100 text-center  m-auto" +
                      " text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 cursor-pointer"
                    }
                    type="button"
                    style={{
                      transition: "all .15s ease",
                      color: Colors.text,
                      backgroundColor: Colors.background,
                    }}
                  >
                    <i className="fas fa-arrow-alt-circle-down"></i> Vraag een
                    offerte
                  </button>
                </Link>
              </div>
            </div>
          </section>
          <LazyLoad height={200} offset={100} name="geschiedenis">
            <section className="relative" name="geschiedenis">
              <div
                className="container px-4 mx-auto rounded-md"
                style={{
                  backgroundColor: Colors.background,
                  color: Colors.text,
                }}
              >
                <div className="items-center lg:flex lg:flex-wrap md:inline-block">
                  <div className="w-full p-5 ml-auto mr-auto md:w-4/12 xs:invisible xs:w-0 md:visible ">
                    <img
                      alt="..."
                      className="max-w-full rounded-lg shadow-lg"
                      src={pngBarrel}
                    />
                  </div>
                  <div className="w-full p-4 ml-auto mr-auto md:w-10/12 lg:w-5/12">
                    <img
                      src={titreHistoire}
                      width="300px"
                      className="m-auto"
                    ></img>
                    <div>
                      <p className="w-full m-auto mt-4 text-lg leading-relaxed text-justify md:h-auto xs:h-80 xs:overflow-auto">
                      Locafut is een startup die is opgericht door bierliefhebbers
                        bierliefhebbers met als doel brouwerijen en andere
                        en andere HORECA spelers, een flexibele dienst aan te bieden voor de verhuur en
                        en onderhoud van hoogwaardige stalen vaten en toebehoren
                        tegen de meest concurrerende tarieven op de markt.
                        Onze ambitie is om brouwers te helpen hun piek
                        hun piekproductie te beheren, alternatieven te overwegen om
                        de stijging van hun productiekosten te beperken
                        een ecologisch verantwoord beleid aan te moedigen door het bevorderen van
                        het onbeperkt gebruik van vaten. Wij blijven flexibel en
                        en maken er een erezaak van om ons aan te passen aan uw behoeften
                        om uw evolutie te begeleiden.
                      </p>
                      <ul className="flex flex-wrap justify-between w-full px-4 mt-6 list-none">
                        <li className="py-2">
                          <div className="flex items-center">
                            <div>
                              <span
                                className="inline-block px-2 py-1 mr-3 text-xs font-semibold uppercase rounded-full"
                                style={{
                                  backgroundColor: Colors.historyTag,
                                  color: Colors.text,
                                }}
                              >
                                <i className="fa fa-thumbs-up"></i>
                              </span>
                            </div>
                            <div>
                              <h4>Onbeperkt gebruik van de trommel</h4>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="flex items-center">
                            <div>
                              <span
                                className="inline-block px-2 py-1 mr-3 text-xs font-semibold uppercase rounded-full"
                                style={{
                                  backgroundColor: Colors.historyTag,
                                  color: Colors.text,
                                }}
                              >
                                <i className="fa fa-trophy"></i>
                              </span>
                            </div>
                            <div>
                              <h4>Concurrerende prijzen</h4>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="flex items-center">
                            <div>
                              <span
                                className="inline-block px-2 py-1 mr-3 text-xs font-semibold uppercase rounded-full"
                                style={{
                                  backgroundColor: Colors.historyTag,
                                  color: Colors.text,
                                }}
                              >
                                <i className="far fa-paper-plane"></i>
                              </span>
                            </div>
                            <div>
                              <h4>Snelheid</h4>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <section className="py-12">
                  <div className="container px-4 mx-auto">
                    <div className="flex flex-wrap justify-center mb-5 text-center">
                      <div className="w-full px-4 lg:w-6/12">
                        <h2 className="text-4xl font-semibold">
                        Ze vertrouwen ons al
                        </h2>
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-center w-full">
                    {imageUrls.map((produits) => (
                      <img className="m-5" width="150px" src={produits}></img>
                    ))}
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </LazyLoad>

          <section className="py-12">
            <div className="container px-4 mx-auto">
              <div className="flex flex-wrap justify-center mb-24 text-center">
                <div className="w-full px-4 lg:w-6/12">
                  <h2 className="text-4xl font-semibold">Onze producten</h2>
                  <p className="m-4 text-lg leading-relaxed text-gray-600">
                  Ontdek onze roestvrijstalen vaten
                  </p>
                </div>
              </div>
              <LazyLoad height={200} offset={100}>
                <div className="flex flex-wrap justify-center">
                  {produits.map((produits) => (
                    <div
                      key={produits.id}
                      className="w-full px-4 mb-12 md:w-6/12 lg:w-3/12 lg:mb-0"
                    >
                      <div className="px-6">
                        <img
                          alt="Image de fût"
                          src={produits.img}
                          className="max-w-full mx-auto rounded-full shadow-lg"
                          style={{ maxWidth: "200px", minHeight: "200px" }}
                        />
                        <div className="pt-6 text-center">
                          <h5 className="text-xl font-bold">{produits.name}</h5>
                          <Button onClick={() => handleModel(produits)}>
                          Bekijk meer
                          </Button>

                          {open && (
                            <Modal setOpen={setOpen} open={open} {...produit}>
                              <Box className="border-none" sx={style}>
                                <div
                                  id="partnerCard"
                                  className="flex flex-col h-auto max-w-sm p-2 overflow-hidden border-none rounded-md text-gray-50"
                                  style={{
                                    backgroundColor: Colors.background,
                                    color: Colors.text,
                                  }}
                                >
                                  <div>
                                    <h3 className="pt-2 pb-4 pl-8 text-xl text-left">
                                      {produits.name}
                                    </h3>
                                  </div>
                                  <div className="flex items-center justify-center h-auto bg-white">
                                    <a
                                      className="flex items-center justify-center"
                                      href="#"
                                      target="_blank"
                                      rel="noreferrer noopener"
                                    >
                                      <img
                                        src={produits.img}
                                        alt="Euro Keg"
                                        className="object-cover w-1/2"
                                      />
                                    </a>
                                  </div>
                                  <div className="grid">
                                    <div className="w-full col-span-3 p-4 pr-0 text-lg">
                                      <h4 className="font-bold underline">
                                        Spécificité
                                      </h4>
                                      <ul>
                                        <li className="w-full my-3 text-left">
                                          {produits.subdescription}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </Box>
                            </Modal>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </LazyLoad>
            </div>
          </section>

          <section
            className="relative block pb-20"
            name="contact"
            style={{
              backgroundColor: Colors.background,
            }}
          >
            <div className="container px-4 mx-auto lg:pt-12 lg:pb-52">
              <div className="flex flex-wrap justify-center text-center">
                <div className="w-full px-4 lg:w-6/12">
                  <img
                    width="300px"
                    className="m-auto lg:py-0 md:py-6 xs:py-10 "
                    src={titreContact}
                  ></img>
                </div>
              </div>
            </div>
          </section>

          {/* <section
            className="relative block py-24 lg:pt-0"
            style={{
              backgroundColor: Colors.background,
            }}
          >
            <div className="container px-4 mx-auto md:px-0 xs:px-0">
              <div className="flex flex-wrap justify-center -mt-48 lg:-mt-64">
                <div className="w-full lg:w-9/12 md:w-11/12 xs:w-full">
                  <div className="relative flex flex-col w-full min-w-0 mb-6 break-words bg-gray-200 rounded-lg shadow-lg">
                    <div className="flex-auto p-5 lg:p-10">
                      <h4 className="text-2xl font-semibold">
                        Demande de devis
                      </h4>
                      <p className="mt-1 mb-4 leading-relaxed text-gray-600">
                        Complètez le formulaire ci-dessous pour obtenir un devis
                        dans moins de 24h.
                      </p>

                      <div className="grid-cols-2 gap-4 md:grid xs:inline">
                        <div className="relative w-full mb-3 xs:mt-8 lg:mt-3 ">
                          <label
                            className="block mb-2 text-xs font-bold text-gray-700 uppercase"
                            htmlFor="full-name"
                          >
                            Nom complet
                          </label>
                          <input
                            type="text"
                            className="w-full px-3 py-3 text-sm text-gray-700 placeholder-gray-400 bg-white border-0 rounded shadow focus:outline-none focus:ring"
                            placeholder="Nom Complet"
                            style={{ transition: "all .15s ease" }}
                          />
                        </div>
                        <div className="relative w-full mb-3 xs:mt-8 lg:mt-3">
                          <label
                            className="block mb-2 text-xs font-bold text-gray-700 uppercase"
                            htmlFor="full-name"
                          >
                            Nom de société
                          </label>
                          <input
                            type="text"
                            className="w-full p-3 text-sm text-gray-700 placeholder-gray-400 bg-white border-0 rounded shadow focus:outline-none focus:ring"
                            placeholder="Nom de société"
                            style={{ transition: "all .15s ease" }}
                          />
                        </div>
                      </div>

                      <div className="grid-cols-2 gap-4 mb-3 xs:mt-8 lg:mt-3 md:grid xs:inline">
                        <div className="relative w-full mt-8 mb-3">
                          <label
                            className="block mb-2 text-xs font-bold text-gray-700 uppercase"
                            htmlFor="email"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            className="w-full px-3 py-3 text-sm text-gray-700 placeholder-gray-400 bg-white border-0 rounded shadow focus:outline-none focus:ring"
                            placeholder="Email"
                            style={{ transition: "all .15s ease" }}
                          />
                        </div>
                        <div className="relative w-full mt-8 mb-3">
                          <label
                            className="block mb-2 text-xs font-bold text-gray-700 uppercase"
                            htmlFor="email"
                          >
                            Telephone
                          </label>
                          <input
                            type="text"
                            className="w-full px-3 py-3 text-sm text-gray-700 placeholder-gray-400 bg-white border-0 rounded shadow focus:outline-none focus:ring"
                            placeholder="Numéro de téléphone"
                            style={{ transition: "all .15s ease" }}
                          />
                        </div>
                      </div>

                      <div className="relative w-full mb-3 xs:mt-8 lg:mt-8">
                        <label
                          className="block mb-2 text-xs font-bold text-gray-700 uppercase"
                          htmlFor="email"
                        >
                          Choix de fût
                        </label>

                        <div className="flex lg:justify-evenly md:justify-start xs:justify-start ">
                          <div className="grid-cols-7 gap-5 mt-2 lg:grid lg:m-auto md:inline-block md:m-0 xs:inline-block xs:m-0 ">
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  className="text-red-800 form-checkbox"
                                  value="#"
                                />
                                <span className="ml-2">Euro Keg 50L</span>
                              </label>
                            </div>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  className="text-red-800 form-checkbox"
                                  value="#"
                                />
                                <span className="ml-2">Euro Keg 30L</span>
                              </label>
                            </div>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  className="text-red-800 form-checkbox"
                                  value="#"
                                />
                                <span className="ml-2">Euro Keg 20L</span>
                              </label>
                            </div>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  className="text-red-800 form-checkbox"
                                  value="#"
                                />
                                <span className="ml-2">Slim Keg 20L</span>
                              </label>
                            </div>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  className="text-red-800 form-checkbox"
                                  value="#"
                                />
                                <span className="ml-2">Slim Keg 15L</span>
                              </label>
                            </div>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  className="text-red-800 form-checkbox"
                                  value="#"
                                />
                                <span className="ml-2">Slim Keg 10L</span>
                              </label>
                            </div>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  className="text-red-800 form-checkbox"
                                  value="#"
                                />
                                <span className="ml-2">Slim Keg 5L</span>
                              </label>
                            </div>
                          </div>
                        </div>

                        
                <div className="flex justify-evenly">
                    <div className="grid-cols-7 gap-5 m-auto mt-2 md:grid xs:inline-block xs:m-0 ">

                    </div>
                </div>
                      </div>

                      <div className="relative w-full mb-3 xs:mt-8 lg:mt-8">
                        <label
                          className="block mb-2 text-xs font-bold text-gray-700 uppercase"
                          htmlFor="email"
                        >
                          Tête de fût
                        </label>

                        <div className="flex">
                          <div className="grid-cols-3 mt-2 md:grid xs:inline-block xs:m-0 ">
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  className="text-red-800 form-checkbox"
                                  value="#"
                                />
                                <span className="ml-2">Tête plate (A)</span>
                              </label>
                            </div>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  className="text-red-800 form-checkbox"
                                  value="#"
                                />
                                <span className="ml-2">Tête plate (S)</span>
                              </label>
                            </div>
                            <div>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  className="text-red-800 form-checkbox"
                                  value="#"
                                />
                                <span className="ml-2">
                                  Tête soutirage KeyKeg
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-evenly">
                          <div className="grid-cols-7 gap-5 m-auto mt-2 md:grid xs:inline-block xs:m-0 "></div>
                        </div>
                      </div>

                      <div className="relative w-full mb-3 xs:mt-8 lg:mt-8">
                        <label
                          className="block mb-2 text-xs font-bold text-gray-700 uppercase"
                          htmlFor="message"
                        >
                          Message
                        </label>
                        <textarea
                          rows="4"
                          cols="80"
                          className="w-full px-3 py-3 text-sm text-gray-700 placeholder-gray-400 bg-white border-0 rounded shadow focus:outline-none focus:ring"
                          placeholder="Ecrire un message..."
                        />
                      </div>
                      <div className="mb-3 text-center xs:mt-8 lg:mt-8">
                        <button
                          className="px-6 py-3 mb-1 mr-1 text-sm font-bold uppercase rounded shadow outline-none active:bg-gray-700 hover:shadow-lg focus:outline-none"
                          type="button"
                          style={{
                            transition: "all .15s ease",
                            backgroundColor: Colors.background,
                            color: Colors.text,
                          }}
                          onClick={() =>
                            Swal.fire({
                              position: "center-center",
                              icon: "success",
                              title: "Votre demande à bien été envoyée",
                              showConfirmButton: false,
                              timer: 1500,
                            }).then(function () {
                              window.location.href = "/";
                            })
                          }
                        >
                          Valider la demande
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <ContactForm/>

          <LazyLoad height={200} offset={100} name="actionnaires">
              <section className="relative bg-black" name="actionnaires" >
                <div
                  className="container m-auto align-center justify-around text-center p-5 rounded-md"
                  style={{
                    backgroundColor: Colors.background,
                    color: Colors.text,
                  }}
                >
                  <section className="py-12">
                    <div className="container">
                      <div className="flex flex-wrap justify-center mb-5 text-center">
                        <div className="">
                          <h2 className="text-4xl font-semibold">
                          Wie we zijn
                          </h2>
                          <p className="w-full m-auto mt-4 text-lg leading-relaxed text-justify md:h-auto">Ontdek het team achter dit project</p>

                        </div>
                      </div>
                      <div className="justify-center m-auto w-full">
                        <ActionaireSwiper/>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
          </LazyLoad>

        </main>
      <Footer />
    </>
  );
}
