import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, getDoc, deleteDoc, doc } from "firebase/firestore";
import {db} from '../firebase-config';
import './kegHead.css'

 
 
const Todo = () => {
    const [todo, setTodo] = useState("");
    const [todoNL, setTodoNL] = useState("");
    const [todoEN, setTodoEN] = useState("");
    const [todos, setTodos] = useState([]);
 
    const addTodo = async (e) => {
        e.preventDefault();  
       
        try {
            const docRef = await addDoc(collection(db, "Todos"), {
              todo: todo,    
              todoNL: todoNL,    
              todoEN: todoEN,    
            });
            console.log("Document written with ID: ", docRef.id);
          } catch (e) {
            console.error("Error adding document: ", e);
          }
          window.location.reload();
    }
 
    const fetchPost = async () => {
       
        await getDocs(collection(db, "Todos"))
            .then((querySnapshot)=>{              
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id }));
                setTodos(newData);                
                console.log(todos, newData);
            })
       
    }
   
    useEffect(()=>{
        fetchPost();
    }, [])

    const deleteNote = async (note) => {
        const noteRef = doc(db, "Todos", note.id);
        await deleteDoc(noteRef);
        window.location.reload();
    };

 
 
    return (
        <section name="kegHead">
            <div className="container px-6 py-10 mx-auto">
            <h1 className="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl my-2 dark:text-white">LISTE DES TÊTES DE FÛT</h1>
            <div className="container flex flex-col gap-3 px-6 py-10 mx-auto">
            <input
                type="text"
                placeholder="Ajouter une tête de fût"
                onChange={(e)=>setTodo(e.target.value)}
            />
            <input
                type="text"
                placeholder="Ajouter une tête de fût en Néerlandais"
                onChange={(e)=>setTodoNL(e.target.value)}
            />
            <input
                type="text"
                placeholder="Ajouter une tête de fût en Anglais"
                onChange={(e)=>setTodoEN(e.target.value)}
            />
            <button
                type="submit"
                className="btn w-1/3"
                onClick={addTodo}
            >
                Ajouter
            </button>
            </div>



                <table className="border-collapse w-1/2 my-5">
                    {/* <thead>
                        <tr>
                            <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                            <input
                                            type="text"
                                            placeholder="Ajouter une tête de fût"
                                            onChange={(e)=>setTodo(e.target.value)}
                                        />
                                        <button
                                            type="submit"
                                            className="btn"
                                            onClick={addTodo}
                                        >
                                            Ajouter
                                        </button>
                            </th>
                        </tr>
                    </thead> */}
                <tbody>

    { todos?.map((todo,id)=>(
                            <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
            <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                <span className="lg:hidden absolute top-0 left-0 bg-green-200 px-2 py-1 text-xs font-bold uppercase" key={id}>tête de fût</span>
                {todo.todo + " / " + todo.todoNL + " / " + todo.todoEN}
            </td>
            <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                <span className="lg:hidden absolute top-0 left-0 bg-green-200 px-2 py-1 text-xs font-bold uppercase">Actions</span>
                <a onClick={()=>{deleteNote(todo)}} className=" cursor-pointer hover:text-white focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete</a>
            </td>
        </tr>
    ))
    }
    </tbody>
    </table>
            </div>
        </section>
    )
}
 
export default Todo