import React, {useState, useEffect} from 'react'

// Import Swiper React components
import { Swiper, SwiperSlide  } from 'swiper/react';
import { Navigation } from 'swiper';

// install Swiper modules

import { db, storage } from "../firebase-config";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import {
    ref,
    getDownloadURL,
    listAll,
} from "firebase/storage";


const ActionnaireSwiper = () => {


      //Fetch for trust partners 
        const [imageUrls, setImageUrls] = useState([]);
        const imagesListRef = ref(storage, "actionnaires/");

        useEffect(() => {
            listAll(imagesListRef).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                setImageUrls((prev) => [...prev, url]);
                });
            });
            });
        }, []);
        //END fetch ELEMENTS from db

    return (
        <Swiper
              modules={[Navigation]}
              navigation
        breakpoints={{
            // when window width is >= 640px
            320: {
            slidesPerView: 1,
            spaceBetween: 30
            },
            // when window width is >= 640px
            640: {
            slidesPerView: 2,
            spaceBetween: 30
            },
            // when window width is >= 768px
            768: {
            slidesPerView: 3,
            spaceBetween: 30
            },
            1024: {
            slidesPerView: 4,
            spaceBetween: 30
            },
            1440: {
            slidesPerView: 4,
            spaceBetween: 30
            },
            1640: {
            slidesPerView: 4,
            spaceBetween: 30
            },
        }}
        keyboard={true}
        mousewheel={true}
        direction={"horizontal"}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true}}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        >
        {imageUrls.map((produits) => (
        <SwiperSlide>
        <div>
            <div className="m-auto rounded-lg mb-5">
                <img className="rounded-lg m-auto" src={produits} alt=""/>
            </div>
        </div>
        </SwiperSlide>
        ))}
        </Swiper>
    )
}

export default ActionnaireSwiper