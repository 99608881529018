import React, { useState, useEffect }from 'react'

import {Container } from "semantic-ui-react";
import SideBar from "../components/Sidebar";


import {
    ref,
    uploadBytes,
    getDownloadURL,
    listAll,
    getStorage, 
     deleteObject,
    list,
  } from "firebase/storage";
  import { v4 } from "uuid";


const ActionnairesSlider = () => {

    const [imageUpload, setImageUpload] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);
  
    const storage = getStorage();
    const imagesListRef = ref(storage, "actionnaires/");
  
    const uploadFile = () => {
      if (imageUpload == null) return;
      const imageRef = ref(storage, `actionnaires/${imageUpload.name + v4()}`);
      uploadBytes(imageRef, imageUpload).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          setImageUrls((prev) => [...prev, url]);
        });
        window.location.reload();
      });
    };
  
    useEffect(() => {
      listAll(imagesListRef).then((response) => {
        response.items.forEach((item) => {
          console.log("checkkkk")
          console.log(item._location.path_)
          getDownloadURL(item).then((url) => {
            setImageUrls((prev) => [...prev, {url:url, path:item._location.path_}]);
          });
        });
      });
    }, []);
  
  // this function is for deleting image from cloud storage
  const deleteFileFromCloudStorage = (path) => {
  // Create a reference to the file to delete
  const desertRef = ref(storage, path);
  
  // Delete the file
  deleteObject(desertRef).then(() => {
  alert("File Deleted!")
  window.location.reload();
  }).catch((error) => {
   alert("Uh-oh, an error occurred!")
  });
  
  }
  

  return (

    <>
      <SideBar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <Container className="py-10" style={{ paddingLeft: 1 + "rem" }}>
        <h1 className="header">
                    Slider Actionnaires
                </h1>
                <section name="kegHead">
        <div className="container px-6 py-10 mx-auto">
        <input
                            type="file"
                            placeholder="Ajouter une partenaire"
                            onChange={(event) => {
                              setImageUpload(event.target.files[0]);
                            }}
                        />
                        <button
                            type="submit"
                            className="cursor-pointer hover:text-white focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900"
                            onClick={uploadFile}
                        >
                            Ajouter
                        </button>


            <table className="border-collapse w-1/2 my-5">
            <tbody>

            { imageUrls.map((obj, id)=>(
                             <tr key={id} className="bg-green-700 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
             <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                 <span className="lg:hidden absolute top-0 left-0 bg-green-200 px-2 py-1 text-xs font-bold uppercase">Partenaire</span>
                 <img className="m-auto" src={obj.url} width="30%" height="30%"></img>
             </td>
             <td className="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                 <span className="lg:hidden absolute top-0 left-0 bg-green-200 px-2 py-1 text-xs font-bold uppercase">Actions</span>
                 <button onClick={()=>{
               deleteFileFromCloudStorage(obj.url)   }} className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Delete</button>
             </td>
         </tr>
                         ))
                     }
    </tbody>
    </table>
        </div>
    </section>
        </Container>
      </div>
    </>

  )
}


export default ActionnairesSlider