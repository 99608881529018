import React from "react";

import CardPageVisits from "components/CardPageVisits.js";
import CardSocialTraffic from "components/CardSocialTraffic.js";

export default function Dashboard() {

    return (
      <>
        <div className="flex flex-wrap mt-4">
          <div className="w-full px-4 mb-12 xl:w-8/12 xl:mb-0">
            <CardPageVisits />
          </div>
          <div className="w-full px-4 xl:w-4/12">
            <CardSocialTraffic />
          </div>
        </div>
      </>
    );
  }
