import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from '../firebase-config';
import { Button, Container } from "semantic-ui-react";
import SideBar from "../components/Sidebar";

const ContactForm2 = () => {
  const [formResponses, setFormResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchPost = async () => { 
    try {
      const querySnapshot = await getDocs(collection(db, "ContactResponses"));
      const newData = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id }));
      // Sort responses by date in descending order
      newData.sort((a, b) => new Date(b.date) - new Date(a.date));
      setFormResponses(newData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching form responses:", error);
    }
  };

  useEffect(() => {
    fetchPost();
  }, []);

  const deleteNote = async (note) => {
    try {
      const noteRef = doc(db, "ContactResponses", note.id);
      await deleteDoc(noteRef);
      setFormResponses(prevResponses => prevResponses.filter(response => response.id !== note.id));
    } catch (error) {
      console.error("Error deleting form response:", error);
    }
  };

  const filteredResponses = formResponses.filter(response =>
    response.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    response.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    response.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    response.phone.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <SideBar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <Container className="py-10" style={{ paddingLeft: 1 + "rem" }}>
          <h1 className="header">
            Formulaires de contact
          </h1>
          <input 
            type="text" 
            placeholder="Recherche..." 
            value={searchQuery} 
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="flex flex-wrap w-full p-10">
              {filteredResponses.map((response, index) => (
                <div className="p-6 w-full m-3 h-fit bg-red-100 rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700" key={index}>
                  <div className="flex flex-col pb-2 border-b-2 border-green-700">
                    <span className="flex justify-between"><span className="mr-5">Date: </span>{response.date}</span>
                    <span className="flex justify-between"><span className="mr-5">Form ID°: </span>{response.id}</span>
                    <span className="flex justify-between"><span className="mr-5">Nom complet: </span>{response.fullName}</span>
                    <span className="flex justify-between"><span className="mr-5">Companie: </span>{response.companyName}</span>
                    <span className="flex justify-between"><span className="mr-5">Email: </span>{response.email}</span>
                    <span className="flex justify-between"><span className="mr-5">Téléphone: </span>{response.phone}</span>
                  </div>
                  <h5 className="my-4 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">Produits demandés:</h5>
                  <div className="grid grid-cols-2 gap-2 mb-2">
                    <div>
                      <span className="font-bold ">Choix de fûts: </span>
                      {response.kegChoice.map((task, index) => (
                        <div key={index}>
                          <ul>
                            <li>- {task.name}</li>
                          </ul>
                        </div>
                      ))}
                    </div>
                    <div>
                      <span className="font-bold ">Choix de têtes de fûts: </span>
                      {response.kegHead.map((task, index) => (
                        <div key={index}>
                          <ul>
                            <li>- {task.name}</li>
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                  <span className="font-bold">Message: </span>
                  <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">{response.message}</p>
                  <div className="flex w-full justify-between">
                    <Button className="remove-btn" onClick={() => deleteNote(response)}>Supprimer</Button>
                    <Button className="contact-btn" as="a" href={"mailto:" + response.email} target="_blank">Contacter</Button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default ContactForm2;
