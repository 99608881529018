import React, {useState, useEffect} from "react";
import Logo from "../../assets/img/logoFutVert.png"
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { BsCaretDownFill } from "react-icons/bs";

import './Links.css'

import { Link, animateScroll as scroll } from "react-scroll";

import Colors from '../components/Colors/Colors.js'





export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1023);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1023);
  };

   const [anchorEl, setAnchorEl] = React.useState(null);
   const open = Boolean(anchorEl);
   const handleClick = (event) => {
     setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
     setAnchorEl(null);
   };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });


  



  return (
    <nav
      className={
        (props.transparent
          ? "top-0 absolute z-50 w-full"
          : "relative shadow-lg bg-white") +
        " flex flex-wrap items-center justify-between md:px-6 md:py-3 xs:p-0 "
      }
    >
      <div className="container flex flex-wrap items-center justify-between px-4 mx-auto xs:p-0">
        <div className="relative flex justify-between w-full lg:w-auto lg:static lg:block lg:justify-start">
          <a
            className={
              (props.transparent ? "text-white" : "text-gray-800") +
              " text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            }
            href="/en"
          >
            <img width="250" height="250" src={Logo}></img>
          </a>
          <button
            className="block px-3 py-1 text-xl leading-none bg-transparent border border-transparent border-solid rounded outline-none cursor-pointer lg:hidden focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <i
              className={
                (props.transparent ? "text-white" : "text-gray-800") +
                " fas fa-bars"
              }
            ></i>
          </button>
        </div>
        <div
          className={
            "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
            (navbarOpen ? " block rounded shadow-lg" : " hidden")
          }
          id="example-navbar-warning"
        >
          {isDesktop ? (
            <ul className="flex flex-col list-none lg:flex-row lg:ml-auto">
              <li className="flex items-center">
                <button />

                {/* <Link 
              className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-lg uppercase font-bold cursor-pointer"
                }
                activeClass="active"
                to="accueuil"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                {" "}
                Accueil
              </Link> */}
              </li>
              <li className="flex items-center">
                <Link
                  className={
                    "px-3 py-4 lg:py-2 flex items-center text-lg uppercase font-bold cursor-pointer link-underline link-underline-black"
                  }
                  style={{
                    color: Colors.text,
                  }}
                  activeClass="active"
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  {" "}
                  Services
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  className={
                    " px-3 py-4 lg:py-2 flex items-center text-lg uppercase font-bold cursor-pointer link-underline link-underline-black"
                  }
                  style={{
                    color: Colors.text,
                  }}
                  activeClass="active"
                  to="avantages"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  {" "}
                  Advantages
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  className={
                    " px-3 py-4 lg:py-2 flex items-center text-lg uppercase font-bold cursor-pointer link-underline link-underline-black"
                  }
                  style={{
                    color: Colors.text,
                  }}
                  activeClass="active"
                  to="history"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  {" "}
                  History
                </Link>
              </li>
              <li className="flex items-center link-underline link-underline-black">
                <Link
                  className={
                    " px-3 py-4 lg:py-2 flex items-center text-lg uppercase font-bold cursor-pointer"
                  }
                  style={{
                    color: Colors.text,
                  }}
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  {" "}
                  Contact
                </Link>
              </li>
              <li className="flex items-center ">
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  sx={{ color: "white", fontWeight: "bold" }}
                  onClick={handleClick}
                >
                  EN <BsCaretDownFill />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <a href="/">
                    <MenuItem onClick={handleClose}>FR</MenuItem>
                  </a>
                  <a href="/nl">
                    <MenuItem onClick={handleClose}>NL</MenuItem>
                  </a>
                </Menu>
              </li>
            </ul>
          ) : (
            <ul className="flex flex-col list-none lg:flex-row lg:ml-auto">
              <li className="flex items-center">
                <button />

                {/* <Link 
              className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-lg uppercase font-bold cursor-pointer"
                }
                activeClass="active"
                to="accueuil"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                {" "}
                Accueil
              </Link> */}
              </li>
              <li className="flex items-center">
                <Link
                  className={
                    "px-3 py-4 lg:py-2 flex items-center text-lg uppercase font-bold cursor-pointer "
                  }
                  style={{
                    color: Colors.background,
                  }}
                  activeClass="active"
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  {" "}
                  Services
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  className={
                    " px-3 py-4 lg:py-2 flex items-center text-lg uppercase font-bold cursor-pointer"
                  }
                  style={{
                    color: Colors.background,
                  }}
                  activeClass="active"
                  to="advantages"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  {" "}
                  Advantages
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  className={
                    " px-3 py-4 lg:py-2 flex items-center text-lg uppercase font-bold cursor-pointer"
                  }
                  style={{
                    color: Colors.background,
                  }}
                  activeClass="active"
                  to="history"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  {" "}
                  History
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  className={
                    " px-3 py-4 lg:py-2 flex items-center text-lg uppercase font-bold cursor-pointer"
                  }
                  style={{
                    color: Colors.background,
                  }}
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  {" "}
                  Contact
                </Link>
              </li>
              <li className="flex items-center ">
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  sx={{ color: "black", fontWeight: "bold", marginLeft:"3px" }}
                  onClick={handleClick}
                >
                  EN <BsCaretDownFill />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <a href="/">
                    <MenuItem onClick={handleClose}>FR</MenuItem>
                  </a>
                  <a href="/nl">
                    <MenuItem onClick={handleClose}>NL</MenuItem>
                  </a>
                </Menu>
              </li>
            </ul>
          )}

          <ul className="flex flex-col list-none lg:flex-row lg:ml-auto">
            <li className="flex items-center">
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                <button
                  className={
                    " text-md font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 cursor-pointer"
                  }
                  type="button"
                  style={{
                    transition: "all .15s ease",
                    color: Colors.background,
                    backgroundColor: Colors.text,
                  }}
                >
                  <i className="fas fa-arrow-alt-circle-down"></i> Request a quote
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}


