import React, { useState, useEffect, useRef } from "react";
import Swal from 'sweetalert2';
import {db} from "../../firebase-config";
import {collection, addDoc, getDocs,updateDoc} from "firebase/firestore"
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';

import Colors from '../components/Colors/Colors.js'

const ContactForm = () => {


    const navigate = useNavigate();
    const [produits, setProduits] = useState([]);
    const [kegHeads, setKegHeads] = useState([]);

    const form = useRef();

    const [formData, setFormData] = useState({
        fullName: "",
        companyName: "",
        email: "",
        phone: "",
        kegChoice : [],
        kegHead: [],
        message: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, "ContactResponses"), {
                fullName: formData.fullName,
                companyName: formData.companyName,
                email: formData.email,
                phone: formData.phone,
                kegChoice: formData.kegChoice,
                kegHead: formData.kegHead,
                message: formData.message,
                date: new Date().toLocaleDateString(),
            }).then(() =>{
                emailjs.sendForm('service_98xejx6', 'template_t8m24jj', form.current, 'k4XtFH5qXnicOG4hn')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
                Swal.fire({
                    position: 'center-center',
                    icon: 'success',
                    title: "Het Locafût-team neemt binnen 24 uur contact met u op om uw verzoek te beantwoorden",
                    showConfirmButton: false,
                    timer: 1500
                    }).then((result) => {
                    window.location.reload(true)
                    window.scrollTo(0, 0);
                    })
            });
            } catch (err) {
            alert(err);
            }
    };


    const fetchPost = async () => {
            await getDocs(collection(db, "Images")).then((querySnapshot) => {
                const newData = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));
            setProduits(newData);
            console.log(produits, newData);
            });
        };

        useEffect(() => {
            fetchPost();
        }, []);
        

        const fetchHead = async () => {
            await getDocs(collection(db, "Todos")).then((querySnapshot) => {
                const newData = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));
            setKegHeads(newData);
            console.log(kegHeads, newData);
            });
        };
    
        useEffect(() => {
            fetchHead();
        }, []);
        

    return (
        <section className="relative block py-24 lg:pt-0"
        style={{
            backgroundColor: Colors.background,
        }}>
        <div className="container px-4 mx-auto md:px-0 xs:px-0">
            <div className="flex flex-wrap justify-center -mt-48 lg:-mt-64">
            <div className="w-full lg:w-9/12 md:w-11/12 xs:w-full">
                <div className="relative flex flex-col w-full min-w-0 mb-6 break-words bg-gray-200 rounded-lg shadow-lg">

                <form ref={form}>
                <div className="flex-auto p-5 lg:p-10">
                    <h4 className="text-2xl font-semibold">Een offerte aanvragen</h4>
                    <p className="mt-1 mb-4 leading-relaxed text-gray-600">
                    Vul het onderstaande formulier in voor een offerte
                    in minder dan 24 uur.
                    </p>

                    <div className="grid-cols-2 gap-4 md:grid xs:inline">
                    <div className="relative w-full mb-3 xs:mt-8 lg:mt-3 ">
                        <label
                        className="block mb-2 text-xs font-bold text-gray-700 uppercase"
                        htmlFor="fullName"
                        >
                        Volledige naam
                        </label>
                        <input
                        required="required"
                        type="text"
                        className="w-full px-3 py-3 text-sm text-gray-700 placeholder-gray-400 bg-white border-0 rounded shadow focus:outline-none focus:ring"
                        placeholder="Volledige naam"
                        style={{ transition: "all .15s ease" }}
                        value={formData.fullName}
                        name="user_name"
                        onChange={(e) => {
                        setFormData({ ...formData, fullName: e.target.value });
                        }}
                        />
                    </div>
                    <div className="relative w-full mb-3 xs:mt-8 lg:mt-3">
                        <label
                        className="block mb-2 text-xs font-bold text-gray-700 uppercase"
                        htmlFor="companyName"
                        >
                        Naam van het bedrijf
                        </label>
                        <input
                        type="text"
                        className="w-full p-3 text-sm text-gray-700 placeholder-gray-400 bg-white border-0 rounded shadow focus:outline-none focus:ring"
                        placeholder="Naam van het bedrijf"
                        style={{ transition: "all .15s ease" }}
                        value={formData.companyName}
                        name="user_company"
                        onChange={(e) => {
                        setFormData({ ...formData, companyName: e.target.value });
                        }}
                        />
                    </div>
                    </div>

                    <div className="grid-cols-2 gap-4 mb-3 xs:mt-8 lg:mt-3 md:grid xs:inline">
                    <div className="relative w-full mt-8 mb-3">
                        <label
                        className="block mb-2 text-xs font-bold text-gray-700 uppercase"
                        htmlFor="email"
                        >
                        Email
                        </label>
                        <input
                        type="email"
                        className="w-full px-3 py-3 text-sm text-gray-700 placeholder-gray-400 bg-white border-0 rounded shadow focus:outline-none focus:ring"
                        placeholder="Email"
                        style={{ transition: "all .15s ease" }}
                        value={formData.email}
                        name="user_email"
                        onChange={(e) => {
                        setFormData({ ...formData, email: e.target.value });
                    
                        }}
                        />
                    </div>
                    <div className="relative w-full mt-8 mb-3">
                        <label
                        className="block mb-2 text-xs font-bold text-gray-700 uppercase"
                        htmlFor="phone"
                        >
                        Telefoonnummer
                        </label>
                        <input
                        type="number"
                        className="w-full px-3 py-3 text-sm text-gray-700 placeholder-gray-400 bg-white border-0 rounded shadow focus:outline-none focus:ring"
                        placeholder="Telefoonnummer"
                        style={{ transition: "all .15s ease" }}
                        value={formData.phone}
                        name="user_phone"
                        onChange={(e) => {
                        setFormData({ ...formData, phone: e.target.value });
                        }}
                        />
                    </div>
                    </div>

                    <div className="relative w-full mb-3 xs:mt-8 lg:mt-8">
                    <label
                        className="block mb-2 text-xs font-bold text-gray-700 uppercase"
                        htmlFor="kegChoice"
                    >
                        Keuze van het vat
                    </label>
                    <div className="flex lg:justify-start md:justify-start xs:justify-start ">
                        <div className="grid-cols-7 mt-2 md:grid xs:inline-block xs:m-0 items-center ">

                        {produits.map((produits) => (
                            <div className="mr-3">
                            <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                className="text-red-800 form-checkbox my-4"
                                value={produits.name}
                                onChange={(e) => {
                                setFormData({ ...formData, kegChoice: [...formData.kegChoice, {name:e.target.value}], multiple: true });
                                }}
                            />
                            <span className="mx-2">{produits.name}</span>
                            </label>
                        </div>
                        ))}
                        </div>
                    </div>

                    {/* 
                <div className="flex justify-evenly">
                    <div className="grid-cols-7 gap-5 m-auto mt-2 md:grid xs:inline-block xs:m-0 ">

                    </div>
                </div> */}
                    </div>

                    <div className="relative w-full mb-3 xs:mt-8 lg:mt-8">
                    <label
                        className="block mb-2 text-xs font-bold text-gray-700 uppercase"
                        htmlFor="kegHead"
                    >
                        Vat hoofd
                    </label>

                    <div className="flex">
                        <div className="grid-cols-5 mt-2 md:grid xs:inline-block xs:m-0 items-center ">

                        {kegHeads.map((kegHeads) => (
                            <div className="mr-3">
                            <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                className="text-red-800 form-checkbox my-4"
                                value={kegHeads.todo}
                                onChange={(e) => {
                                setFormData({ ...formData, kegHead: [...formData.kegHead, {name:e.target.value}], multiple: true });
                                }}
                            />
                            <span className="m-2">{kegHeads.todoNL}</span>
                            </label>
                        </div>
                        ))}

                        </div>
                    </div>

                    <div className="flex justify-evenly">
                        <div className="grid-cols-7 gap-5 m-auto mt-2 md:grid xs:inline-block xs:m-0 "></div>
                    </div>
                    </div>

                    <div className="relative w-full mb-3 xs:mt-8 lg:mt-8">
                    <label
                        className="block mb-2 text-xs font-bold text-gray-700 uppercase"
                        htmlFor="message"
                    >
                        Bericht
                    </label>
                    <textarea
                        rows="4"
                        cols="80"
                        name="message"
                        className="w-full px-3 py-3 text-sm text-gray-700 placeholder-gray-400 bg-white border-0 rounded shadow focus:outline-none focus:ring"
                        placeholder="Bericht..."
                        value={formData.message}
                                onChange={(e) => {
                                setFormData({ ...formData, message: e.target.value });
                                }}
                    />
                    </div>
                    <div className="mb-3 text-center xs:mt-8 lg:mt-8">
                    <button
                        className="px-6 py-3 mb-1 mr-1 text-sm font-bold uppercase rounded shadow outline-none active:bg-gray-700 hover:shadow-lg focus:outline-none"
                        type="submit"
                        style={{ transition: "all .15s ease", backgroundColor: Colors.background, color: Colors.text}}
                        onClick={handleSubmit}
                        >
                        Het verzoek valideren
                    </button>
                    </div>
                </div>
                    </form>
                </div>
            </div>
            </div>
        </div>
        </section>
    )
}

export default ContactForm