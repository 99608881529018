import React from "react";
import { Routes, Route, Navigate} from "react-router-dom";
import AdminNavbar from "components/AdminNavbar.js";
import Sidebar from "components/Sidebar.js";
import HeaderStats from "components/HeaderStats.js";
import TrustPartners from '../layouts/TrustPartners.js'
import KegHead from './KegHead'
import ContactFomResponse from './ContactForm'

// views

import Dashboard from "views/Dashboard";
import Login from "views/Login";
import Data from './Data'

import ContactForm2 from './ContactForm2'


export default function Admin() {
  return (
    <>
      <Sidebar/>
      <div className="relative md:ml-64 bg-blueGray-100">
        {/* <Data/> */}
        {/* Header */}
        {/* <HeaderStats /> */}
        <TrustPartners />
        {/* <KegHead/> */}
        {/* <ContactFomResponse/> */}
        {/* <ContactForm2/> */}
        <div class="ml-auto mb-6 ">
</div>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Routes>
            <Route path="/admin" exact component={<Dashboard/>} />
            <Route path="/login" exact component={<Login/>} />
          </Routes>
        <div className="w-full px-4 mx-auto -m-24 md:px-10">
        </div>
        </div>
      </div>
    </>
  )};