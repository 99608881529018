import React from "react";

import Logo from '../../assets/img/LocaFutSVG.png'

export default function Footer() {
  return (
    <footer className="relative pt-8 pb-6 bg-white">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap">
            <div className="w-full px-4 lg:w-4/12 lg:-mt-16 lg:-ml-8 md:mx-auto xs:m-auto">
            <img src={Logo} width="400px" height=""></img>
            </div>
            <div className="w-full px-4 text-center lg:w-4/12">
            <h4 className="text-2xl font-semibold">Let's keep in touch !</h4>
            <h5 className="mt-0 mb-2 text-lg text-gray-700">
              Find us on these platforms and we'll answer all your questions.
            </h5>
            <div className="my-6">
              <button
                className="items-center justify-center w-10 h-10 p-3 mr-2 font-normal text-blue-400 bg-white rounded-full shadow-lg outline-none align-center focus:outline-none"
                type="button"
              >
                <i className="flex fab fa-twitter"></i>
              </button>
              <button
                className="items-center justify-center w-10 h-10 p-3 mr-2 font-normal text-blue-600 bg-white rounded-full shadow-lg outline-none align-center focus:outline-none"
                type="button"
              >
                <i className="flex fab fa-facebook-square"></i>
              </button>
              <button
                className="items-center justify-center w-10 h-10 p-3 mr-2 font-normal text-pink-400 bg-white rounded-full shadow-lg outline-none align-center focus:outline-none"
                type="button"
              >
                <i className="flex fab fa-instagram"></i>
              </button>
            </div>
            </div>
            <div className="w-full lg:w-4/12">
            <div className="flex flex-wrap mb-6 items-top">
              <div className="w-full px-4 ml-auto lg:w-4/12">
                {/* <span className="block mb-2 text-sm font-semibold text-gray-600 uppercase">
                  Liens utiles
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="block pb-2 text-sm font-semibold text-gray-700 hover:text-gray-900"
                      href="#"
                    >
                      A propos
                    </a>
                  </li>
                  <li>
                    <a
                      className="block pb-2 text-sm font-semibold text-gray-700 hover:text-gray-900"
                      href="#"
                    >
                      Blog
                    </a>
                  </li>
                </ul> */}
              </div>
              <div className="w-full px-4 lg:w-4/12">
                <span className="block mb-2 text-sm font-semibold text-gray-600 uppercase">
                Other resources
                </span>
                <ul className="list-unstyled">

                  <li>
                    <a
                      className="block pb-2 text-sm font-semibold text-gray-700 hover:text-gray-900"
                    
                    >
                      Legal information (available on request and/or mentioned in our rental contract)
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="block pb-2 text-sm font-semibold text-gray-700 hover:text-gray-900"
                      href="#"
                    >
                      Politique de confidentialité
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      className="block pb-2 text-sm font-semibold text-gray-700 hover:text-gray-900"
                      href="#"
                    >
                      Contact
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            </div>
        </div>
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center justify-center md:justify-between">
          <div className="w-full px-4 mx-auto text-center md:w-4/12">
            <div className="py-1 text-sm font-semibold text-gray-600">
              Copyright © {new Date().getFullYear()} LocaFût by{" "}
              <a
                href="https://www.digiiitall.com/en/home"
                className="text-gray-600 hover:text-gray-900"
              >
                DIGIIITALL
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
