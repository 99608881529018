import { useContext } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import "./index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";



import Landing from "views/Landing.js";
import ProduitsAdmin from "views/ProduitsAdmin";
import Admin from "layouts/Admin.js";
import LandingDutch from "./DUTCH/views/Landing";
import LandingEnglish from "./ENGLISH/views/Landing";
import AddProduct from "views/AddProduct";
import Login from "views/Login";
import ContactForm2 from "layouts/ContactForm2";
import Actionnaires from "./views/Actionnaires";

function App() {
  const { currentUser } = useContext(AuthContext);


  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <div>

      <Router>
          <Routes >
            <Route path="/">
              {/* Routes Front */}
              <Route path="login" element={<Login />} />

              <Route index element={<Landing />} />
              <Route path="nl" element={<LandingDutch />} />
              <Route path="en" element={<LandingEnglish />} />

              {/* Routes Admin */}

              <Route
                path="admin/add"
                element={
                  <RequireAuth>
                    <AddProduct />
                  </RequireAuth>
                }
              />
              <Route
                path="admin/update/:id"
                element={
                  <RequireAuth>
                    <AddProduct />
                  </RequireAuth>
                }
              />
              <Route
                path="admin/*"
                element={
                  <RequireAuth>
                    <Admin />
                  </RequireAuth>
                }
              />
              <Route
                path="admin/products"
                element={
                  <RequireAuth>
                    <ProduitsAdmin />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="admin/contactForm"
                element={
                  <RequireAuth>
                    <ContactForm2 />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="admin/actionnaires"
                element={
                  <RequireAuth>
                    <Actionnaires />
                  </RequireAuth>
                }
              ></Route>
            </Route>
          </Routes>
      </Router>
      <Router />
    </div>
  );
}

export default App;
