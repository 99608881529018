import React, { useState, useEffect } from "react";
import { Button, Form, Grid, Loader, Header } from "semantic-ui-react";
import SideBar from "../components/Sidebar";
import { db, storage } from "../firebase-config";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

const initialState = {
  name: "",
  description: "",
  subdescription: "",
};

const AddEditUser = () => {
  const [data, setData] = useState(initialState);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const { name, description, subdescription, descriptionNL, descriptionENG, subdescriptionNL, subdescriptionENG } = data;
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    id && getSingleUser();
  }, [id]);

  const getSingleUser = async () => {
    const docRef = doc(db, "Images", id);
    const snapshot = await getDoc(docRef);

    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  useEffect(() => {
    
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is Paused");
              break;
            case "running":
              console.log("Upload is Running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const validate = () => {
    let errors = {};
    if (!name) {
      errors.name = "Un nom est nécessaire";
    }
    if (!description) {
      errors.description = "Une description est nécessaire";
    }
    if (!descriptionNL) {
      errors.description = "Une description en néerlandais est nécessaire";
    }
    if (!descriptionENG) {
      errors.description = "Une description en anglais est nécessaire";
    }

    if (!subdescription) {
      errors.subdescription = "La sous-description est nécessaire";
    }
    if (!subdescriptionNL) {
      errors.subdescription = "La sous-description en néerlandais est nécessaire";
    }
    if (!subdescriptionENG) {
      errors.subdescription = "La sous-description en anglais est nécessaire";
    }
    return errors;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let errors = validate();
    if (Object.keys(errors).length) {
      return setErrors(errors);
    }
    setIsSubmit(true);
    if (!id) {
      try {
        await addDoc(collection(db, "Images"), {
          ...data,
          timestamps: serverTimestamp(),
        });
      } catch (error) {
        console.log(error.message);
      }
    } else {
      try {
        await updateDoc(doc(db, "Images", id), {
          ...data,
          timestamps: serverTimestamp(),
        });
      } catch (error) {
        console.log(error.message);
      }
    }

    navigate("/admin/products");
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <SideBar></SideBar>
      <Grid
        centered
        verticalAlign="middle"
        columns={3}
        style={{ height: "80vh" }}
      >
        <Grid.Row>
          <Grid.Column textAlign="center">
            <div>
              {isSubmit ? (
                <Loader active inline="centered" size="huge"></Loader>
              ) : (
                <>
                  <Header style={{paddingTop: 2 +"rem"}}>{id ? "Mettre à jour" : "Ajouter un produit"}</Header>
                  <Form onSubmit={submitHandler}>
                    {/* NOM DU PRODUIT */}
                    <Form.Input
                      label="Produit"
                      error={errors.name ? { content: errors.name } : null}
                      placeholder="Nom de votre produit"
                      name="name"
                      onChange={handleChange}
                      value={name}
                      autoFocus
                    ></Form.Input>

                    {/* DESCRIPTIONS MULTILANGUES */}
                    <Form.Input
                      label="Description"
                      error={
                        errors.description
                          ? { content: errors.description }
                          : null
                      }
                      placeholder="Tailles"
                      name="description"
                      onChange={handleChange}
                      value={description}
                    ></Form.Input>
                    <Form.Input
                      label="Description (Néerlandais)"
                      error={
                        errors.descriptionNL
                          ? { content: errors.descriptionNL }
                          : null
                      }
                      placeholder="Tailles"
                      name="descriptionNL"
                      onChange={handleChange}
                      value={descriptionNL}
                    ></Form.Input>
                    <Form.Input
                      label="Description (Anglais)"
                      error={
                        errors.descriptionENG
                          ? { content: errors.descriptionENG }
                          : null
                      }
                      placeholder="Tailles"
                      name="descriptionENG"
                      onChange={handleChange}
                      value={descriptionENG}
                    ></Form.Input>

                    {/* SOUS DESCRIPTIONS MULTILANGUES */}
                    <Form.Input
                      label="Sous-Description"
                      error={
                        errors.subdescription
                          ? { content: errors.subdescription }
                          : null
                      }
                      placeholder="La description du produit"
                      name="subdescription"
                      onChange={handleChange}
                      value={subdescription}
                    ></Form.Input>
                    <Form.Input
                      label="Sous-Description (Néerlandais)"
                      error={
                        errors.subdescriptionNL
                          ? { content: errors.subdescriptionNL }
                          : null
                      }
                      placeholder="La description du produit"
                      name="subdescriptionNL"
                      onChange={handleChange}
                      value={subdescriptionNL}
                    ></Form.Input>
                    <Form.Input
                      label="Sous-Description (Anglais)"
                      error={
                        errors.subdescriptionENG
                          ? { content: errors.subdescriptionENG }
                          : null
                      }
                      placeholder="La description du produit"
                      name="subdescriptionENG"
                      onChange={handleChange}
                      value={subdescriptionENG}
                    ></Form.Input>
                    {/* UPLOAD */}

                    <Form.Input
                      label="Upload"
                      type="file"
                      onChange={(e) => setFile(e.target.files[0])}
                    ></Form.Input>

                    <Button
                      type="submit"
                      primary
                      disabled={progress != null && progress < 100}
                    >
                      Soumettre
                    </Button>

                  </Form>
                  
                  <a href="/admin/products">
                    <Button
                      basic
                      style={{ marginTop: 2 + "rem" }}
                      color="green"
                    >
                      Revenir en arrière
                    </Button>
                  </a>
                </>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default AddEditUser;
