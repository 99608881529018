import React, {useState, useEffect} from 'react'

import { storage } from "../firebase-config";
import { db } from "../firebase-config";

import { useNavigate } from "react-router-dom";

import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";

import { Button } from "semantic-ui-react";

import {deleteObject, ref} from "firebase/storage"
import ModelComponent from "../components/Modal";
const ProductList2 = () => {

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState({});
    const navigate = useNavigate();

    const handleModel = (item) => {
        setOpen(true);
        setUser(item);
    };

    const deleteFileFromCloudStorage = (path) => {
     // Create a reference to the file to delete
    const desertRef = ref(storage, path);

    // Delete the file
    deleteObject(desertRef)
    .then(() => {
        alert("Fichier supprimé!");
    })
    .catch((error) => {
        alert("Une erreur est survenue, contactez Digiiitall!");
    });
    };

    const handleDelete = async (id) => {
        if (window.confirm("Etes-vous sûr de supprimer ce produit?")) {
        try {
            setOpen(false);
            await deleteDoc(doc(db, "Images", id));
            setUsers(users.filter((user) => user.id !== id));
        } catch (error) {
            console.log(error);
        }
        }
    };

    useEffect(() => {
        setLoading(true);
        const unsub = onSnapshot(
        collection(db, "Images"),
        (snapshot) => {
            let list = [];
            snapshot.docs.forEach((doc) => {
            list.push({ id: doc.id, ...doc.data() });
            });
            setUsers(list);
            setLoading(false);
        },
        (error) => {
            console.log(error.message);
        }
        );
        return () => {
        unsub();
        };
    }, []);

  return (

    <div className="container px-6 lg:py-10 xs:py-2 mx-auto">
                <h1 className="text-3xl font-semibold text-gray-800 capitalize lg:text-4xl my-2 dark:text-white">LISTES DES FÛTS</h1>
                <a href="/admin/add">
                <Button style={{ marginBottom: 2 + "rem" }} basic color="green">
                    Ajouter un nouveau produit
                </Button>
            </a>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">

        {users && users.map((item) => (
            <div className="bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700" key={item.id}>
                <img className="rounded-t-lg object-cover object-top h-52 w-full" src={item.img} alt="" />
                <div className="p-5">
                    <h5 className="mb-5 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{item.name}</h5>
                    <span className="underline font-semibold ">Descriptions:</span>
                    <p className="mb-2 font-normal text-gray-700 dark:text-gray-400">FR: {item.description}</p>
                    <p className="mb-2 font-normal text-gray-700 dark:text-gray-400">EN: {item.descriptionENG}</p>
                    <p className="mb-2 font-normal text-gray-700 dark:text-gray-400">NL: {item.descriptionNL}</p>
                    <span className="underline font-semibold ">Subdescriptions:</span>
                    <p className="mb-2 font-normal text-gray-700 dark:text-gray-400">FR: {item.subdescription}</p>
                    <p className="mb-2 font-normal text-gray-700 dark:text-gray-400">EN: {item.subdescriptionENG}</p>
                    <p className="mb-2 font-normal text-gray-700 dark:text-gray-400">NL: {item.subdescriptionNL}</p>
                    <div className="flex flex-wrap w-full justify-evenly">
                        <button onClick={() => navigate(`/admin/update/${item.id}`)} className="bg-orange-500 px-4 py-2 text-sm text-white inline-flex items-center space-x-2 rounded m-1">
                                    <span>Update</span>
                        </button>

                        <button onClick={() => handleModel(item)} className="bg-green-500 px-4 py-2 text-sm text-white inline-flex items-center space-x-2 rounded m-1">
                                    <span>Details</span>
                        </button>

                        <button onClick={() => {
                                deleteFileFromCloudStorage(item.img);
                                handleDelete(item.id);
                                }} className="bg-red-600 px-4 py-2 text-sm text-white inline-flex items-center space-x-2 rounded m-1">
                                    <span>Delete</span>
                        </button>
                    </div>
                </div>
            </div>
        ))}

        </div>
    </div>
    

  )
}

export default ProductList2